export default {
  props: {
  },
  computed: {
    gridColumns() {
      let cols = null;
      // 공정 - 구분 - 유해위험요인 - 현상태 - 위험도(전) - 조치 - 위험도(후) - 개선
      if (this.popupParam.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        cols = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'diagramTitle',
            field: 'diagramTitle',
            description: 'diagramNo',
            label: '도면',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            fix: true,
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeNo',
            label: '노드',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            fix: true,
            name: 'deviationName',
            field: 'deviationName',
            label: '이탈',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            label: '원인',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            label: '현재안전조치',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            label: '개선전',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                label: '강도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                label: '위험도',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            label: '추가 리스크관리 계획',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'after',
            field: 'after',
            label: '개선후',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                label: '강도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                label: '위험도',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선',
            align: 'center',
            style: 'width:130px',
            type: 'custom',
            sortable: false
          },
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000005') {
        // K-PSR
        cols = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'diagramTitle',
            field: 'diagramTitle',
            description: 'diagramNo',
            label: '도면',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            fix: true,
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeNo',
            label: '노드',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskTypName',
            field: 'riskTypName',
            label: '위험형태',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            label: '원인',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            label: '현재안전조치',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            label: '추가 리스크관리 계획',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선',
            align: 'center',
            style: 'width:130px',
            type: 'custom',
            sortable: false
          },
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000010') {
        // Check-list
        // 일단 보류, check-list 구성 후 다시 진행
        cols = [
          
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000015') {
        // JRA
        cols = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'jobName',
            field: 'jobName',
            label: '작업',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'jobStepName',
            field: 'jobStepName',
            description: 'jobStepDesc',
            label: '작업단계',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskHazardNameClass',
            field: 'riskHazardNameClass',
            label: '분류-유해위험요인',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            label: '원인',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            label: '현재안전조치',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            label: '개선전',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                label: '강도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                label: '위험도',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            label: '추가 리스크관리 계획',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'after',
            field: 'after',
            label: '개선후',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                label: '강도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                label: '위험도',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선',
            align: 'center',
            style: 'width:130px',
            type: 'custom',
            sortable: false
          },
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000020') {
        // KRAS
        cols = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'jobName',
            field: 'jobName',
            label: '작업',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '분류-유해위험요인',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            label: '원인',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            label: '현재안전조치',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            label: '개선전',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                label: '강도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                label: '위험도',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            label: '추가 리스크관리 계획',
            align: 'left',
            style: 'width:150px',
            sortable: false
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
          {
            name: 'after',
            field: 'after',
            label: '개선후',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                label: '강도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                label: '위험도',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000025') {
        // 4M
        cols = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'jobName',
            field: 'jobName',
            label: '작업',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '유해위험요인',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            label: '현재안전조치',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            label: '개선전',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                label: '강도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                label: '위험도',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'addingRiskManagementActivities',
            field: 'addingRiskManagementActivities',
            label: '추가 리스크관리 계획',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
          {
            name: 'after',
            field: 'after',
            label: '개선후',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                label: '강도',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                label: '위험도',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000030') {
        // CHARM
        // 사용 X
        cols = [

        ]
      }
      return cols;
    },
    gridMerge() {
      let merge = [];
      // 공정 - 구분 - 유해위험요인 - 현상태 - 위험도(전) - 조치 - 위험도(후) - 개선
      if (this.popupParam.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
          { index: 1, colName: 'processCd' }, // 도면
          { index: 2, colName: 'processCd' }, // 노드
          { index: 3, colName: 'processCd' }, // 이탈
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000005') {
        // K-PSR
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
          { index: 1, colName: 'diagramGubun' }, // 도면
          { index: 2, colName: 'nodeGubun' }, // 노드
          { index: 3, colName: 'riskTypeGubun' }, // 위험형태
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000010') {
        // Check-list
        // 일단 보류, check-list 구성 후 다시 진행
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000015') {
        // JRA
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
          { index: 1, colName: 'jobGubun' }, // 작업
          { index: 2, colName: 'jobStepGubun' }, // 작업단계
          { index: 3, colName: 'riskHazardGubun' }, // 유해위험요인
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000020') {
        // KRAS
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
          { index: 1, colName: 'processCd' }, // 작업
          { index: 2, colName: 'processCd' }, // 유해위험요인
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000025') {
        // 4M
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
          { index: 1, colName: 'jobGubun' }, // 작업
          { index: 2, colName: 'riskHazardGubun' }, // 유해위험요인 (구분과 같이)
        ]
      } else if (this.popupParam.ramTechniqueCd === 'RT00000030') {
        // CHARM
        // 사용 X
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
        ]
      }
      return merge;
    },
    imprProps() {
      let props = {};
      // 공정 - 구분 - 유해위험요인 - 현상태 - 위험도(전) - 조치 - 위험도(후) - 개선
      if (this.popupParam.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        props = {
          tableKey: 'ramHazopAssessScenarioId', ibmTaskTypeCd: 'ITT0000001',
          requestContentsCols: ['deviationName', 'riskOccurrenceCause']
        }
      } else if (this.popupParam.ramTechniqueCd === 'RT00000005') {
        // K-PSR
        props = {
          tableKey: 'ramKpsrAssessScenarioId', ibmTaskTypeCd: 'ITT0000005',
          requestContentsCols: ['riskTypName', 'riskOccurrenceCause']
        }
      } else if (this.popupParam.ramTechniqueCd === 'RT00000010') {
        // Check-list
        // 일단 보류, check-list 구성 후 다시 진행
        props = {
          tableKey: 'ramChecklistAssessScenarioId', ibmTaskTypeCd: 'ITT0000010',
          requestContentsCols: ['riskHazardName', 'riskOccurrenceCause']
        }
      } else if (this.popupParam.ramTechniqueCd === 'RT00000015') {
        // JRA
        props = {
          tableKey: 'ramJsaAssessScenarioId', ibmTaskTypeCd: 'ITT0000015',
          requestContentsCols: ['jobStepName', 'riskHazardNameClass', 'riskHazardName']
        }
      } else if (this.popupParam.ramTechniqueCd === 'RT00000020') {
        // KRAS
        props = {
          tableKey: 'ramKrasAssessScenarioId', ibmTaskTypeCd: 'ITT0000020',
          requestContentsCols: ['jobName', 'ram4mRiskHazardClassName', 'riskOccurrenceCause']
        }
      } else if (this.popupParam.ramTechniqueCd === 'RT00000025') {
        // 4M
        props = {
          tableKey: 'ram4mAssessScenarioId', ibmTaskTypeCd: 'ITT0000023',
          requestContentsCols: ['jobName', 'ram4mRiskHazardClassName', 'riskHazardName']
        }
      } else if (this.popupParam.ramTechniqueCd === 'RT00000030') {
        // CHARM
        // 사용 X
        props = {
          tableKey: 'ramCharmResultAssessScenarioId', ibmTaskTypeCd: 'ITT0000024',
          requestContentsCols: ['materialName']
        }
      }
      return props;
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
}
